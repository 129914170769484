<template>
	<router-view />
</template>

<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Users',
}
</script>
